<template>
	<div>
		<a-card title="Lead Timeline" :headStyle="{ position: 'sticky', top: '0', background: '#fff', 'z-index': '1' }">
			<template slot="extra">
				<div class="dF aC" style="gap: 10px;">
					<div class="dF aC" style="gap: 10px;">
						<div class="text-md">Filter by:</div>
						<a-select :value="filterActivities" mode="multiple" style="width: 300px" size="large"
							:maxTagCount="1" @change="onFilterChange" allowClear>
							<a-select-option value="all" label="All">All</a-select-option>
							<a-select-option v-for="activity in actionList" :key="activity.value"
								:value="activity.value">
								{{ activity.name }}
							</a-select-option>
						</a-select>
					</div>
					<a-divider type="vertical" style="height: 30px;" />
					<div class="dF aC" style="gap: 10px;">
						<div class="text-md">Sort by:</div>
						<a-select v-model="sortBy" style="width: 300px" size="large">
							<a-select-option v-for="sort in sortOptionList" :key="sort.value" :value="sort.value">
								{{ sort.name }}
							</a-select-option>
						</a-select>
					</div>
				</div>
			</template>
			<div class="relative">
				<BHLoading :show="loading" style="z-index: 1;" />
				<a-timeline class="timeline">
					<div class="dF text-md mt-2" v-for="activity in timeline" :key="activity.id"
						@click="onActivitySelect(activity)">
						<div class="dateTime" style="width: 200px;">
							{{ moment(activity.createdAt).format('lll') }}
						</div>
						<a-timeline-item>
							<img slot="dot" :src="activityDetails[activity.data.action].iconUrl" :alt="activity.data.action"
								class="icon">
							<div class="activity ml-3"
								:class="activity.id === (selectedActivity && selectedActivity.id) ? 'selected-activity' : ''">
								{{ activityDetails[activity.data.action].name || activity.data.action }}
							</div>
							<div class="activity-details">
								<template v-if="activity.data.action === 'contact created' && contact">
									<div><span class="title"> Name:</span> {{ contact.firstName + ' ' +
										contact.lastName }}</div>
									<div><span class="title"> Email:</span> {{ contact.email }}</div>
									<div><span class="title"> Source:</span> {{ sources[contact.source] ?
										sources[contact.source].name : contact.source }}
									</div>
									<div><span class="title" v-if="activity.data && activity.data.source"> Source
											Type:</span>
										{{ sourceTypes[activity.data.source] || activity.data.source }}
									</div>
									<div><span class="title" v-if="contact.sourceUrl"> Source URL:</span> {{
										contact.sourceUrl }}</div>
								</template>
								<template v-else-if="activity.data.action === 'appointment created'">
									<div><span class="title"> Title:</span> {{ activity.data.appointment.title }}</div>
									<div><span class="title"> Type:</span> {{
										appointmentTypes[activity.data.appointment.type] ||
										activity.data.appointment.type }}</div>
									<div>
										<span class="title"> Date:</span> {{
											moment(activity.data.appointment.date).format('ll') }}
									</div>
									<div>
										<span class="title"> Start Time:</span> {{
											moment(activity.data.appointment.startTime).format('LT') }}
									</div>
									<div>
										<span class="title"> End Time:</span> {{
											moment(activity.data.appointment.endTime).format('LT') }}
									</div>
								</template>
								<template v-else-if="activity.data.action === 'opportunity created'">
									<div><span class="title">Name: </span> {{ activity.data.opportunity.name }}</div>
									<div><span class="title">Deal value:</span>
										${{ $formatNumber(activity.data.opportunity.dealValue) }}
									</div>
								</template>
								<template v-else-if="activity.data.action === 'task created'">
									<div><span class="title">Title: </span> {{ activity.data.task.title }}</div>
									<div><span class="title">Due Date:</span> {{
										moment(activity.data.task.dueDate).format('lll') }}</div>
								</template>
								<template v-else-if="activity.data.action === 'task completed'">
									<div><span class="title">Title: </span> {{ activity.data.task.title }}</div>
									<div v-if="activity.data.task.sentiment"><span class="title">Outcome Sentiment:
										</span>
										{{ activity.data.task.sentiment }}</div>
									<div><span class="title">Due Date:</span> {{
										moment(activity.data.task.dueDate).format('lll') }}</div>
									<div><span class="title">Completion Date:</span> {{
										moment(activity.createdAt).format('lll') }}</div>
								</template>
								<template v-else-if="activity.data.action === 'form submitted'">
									<div><span class="title">Name: </span> {{ activity.data.form.name }}</div>
								</template>
								<template v-else-if="activity.data.action === 'broadcast sent'">
									<div><span class="title">Name: </span> {{ activity.data.broadcast.name.replace(` -
										${activity.data.broadcast.subject}`, '') }}</div>
									<div><span class="title">Subject: </span> {{ activity.data.broadcast.subject }}
									</div>
									<div><span class="title">From: </span> {{ activity.data.broadcast.from.email }}
									</div>
									<div v-if="activity.data.broadcast.marketing_workflow">
										<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${activity.data.broadcast.marketing_workflow}`"
											class="link" target="_blank">Marketing Workflow</a>
									</div>
								</template>
								<template v-else-if="activity.data.action === 'broadcast email opened'">
									<div><span class="title">Name: </span> {{ activity.data.broadcast.name.replace(` -
										${activity.data.broadcast.subject}`, '') }}</div>
									<div><span class="title">Subject: </span> {{ activity.data.broadcast.subject }}
									</div>
									<div><span class="title">From: </span> {{ activity.data.broadcast.from.email }}
									</div>
									<div><span class="title">Opened Date:</span> {{
										moment(activity.createdAt).format('lll') }}</div>
									<div v-if="activity.data.broadcast.marketing_workflow">
										<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${activity.data.broadcast.marketing_workflow}`"
											class="link" target="_blank">Marketing Workflow</a>
									</div>
								</template>
								<template v-else-if="activity.data.action === 'broadcast email clicked'">
									<div><span class="title">Name: </span> {{ activity.data.broadcast.name.replace(` -
										${activity.data.broadcast.subject}`, '') }}</div>
									<div><span class="title">Subject: </span> {{ activity.data.broadcast.subject }}
									</div>
									<div><span class="title">From: </span> {{ activity.data.broadcast.from.email }}
									</div>
									<div><span class="title">Clicked URL:</span> {{ activity.data.url }}</div>
									<div><span class="title">Clicked Date:</span> {{
										moment(activity.createdAt).format('lll') }}</div>
									<div v-if="activity.data.broadcast.marketing_workflow">
										<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${activity.data.broadcast.marketing_workflow}`"
											class="link" target="_blank">Marketing Workflow</a>
									</div>
								</template>
								<template v-else-if="activity.data.action === 'personal email opened'">
									<div><span class="title">Subject: </span> {{ activity.data.email.subject }}</div>
									<div><span class="title">From: </span> {{ activity.data.email.from.email }}</div>
									<div><span class="title">Opened Date:</span> {{
										moment(activity.createdAt).format('lll') }}</div>
									<div v-if="activity.data.email.marketing_workflow">
										<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${activity.data.email.marketing_workflow}`"
											class="link" target="_blank">Marketing Workflow</a>
									</div>
								</template>
								<template v-else-if="activity.data.action === 'personal email clicked'">
									<div><span class="title">Subject: </span> {{ activity.data.email.subject }}</div>
									<div><span class="title">From: </span> {{ activity.data.email.from.email }}</div>
									<div><span class="title">Clicked URL:</span> {{ activity.data.url }}</div>
									<div><span class="title">Clicked Date:</span> {{
										moment(activity.createdAt).format('lll') }}</div>
									<div v-if="activity.data.email.marketing_workflow">
										<a :href="`https://marketing-automation.bildhive.${$tld}/workflow/view/${activity.data.email.marketing_workflow}`"
											class="link" target="_blank">Marketing Workflow</a>
									</div>
								</template>
								<template v-else-if="activity.data.action === 'transaction created'">
									<div><span class="title">Name: </span> {{ activity.data.transaction.name }}</div>
									<div><span class="title">Type: </span> {{ activity.data.transaction.type }}</div>
									<div><span class="title">Offer Type: </span> {{ activity.data.transaction.offerType
										}}
									</div>
								</template>
								<template v-else-if="activity.data.action === 'lead score added'">
									<div><span class="title">Source: </span> {{ sourceTypes[activity.data.source] ||
										activity.data.source }} </div>
									<div><span class="title">Marketing Workflow: </span> {{
										activity.data.contact.marketingWorkflow }}</div>
									<div><span class="title">Previous Lead Score: </span> {{
										activity.data.contact.previousScore }}</div>
									<div><span class="title">Added Lead Score: </span> + {{
										activity.data.contact.addedScore
									}}
									</div>
								</template>
								<template v-else-if="activity.data.action === 'lead score reduced'">
									<div><span class="title">Source: </span> {{ sourceTypes[activity.data.source] ||
										activity.data.source }} </div>
									<div><span class="title">Marketing Workflow: </span> {{
										activity.data.contact.marketingWorkflow }}</div>
									<div><span class="title">Previous Lead Score: </span> {{
										activity.data.contact.previousScore }}</div>
									<div><span class="title">Reduced Lead Score: </span> - {{
										activity.data.contact.reducedScore }}
									</div>
								</template>
							</div>
						</a-timeline-item>
					</div>
					<template v-if="!loading && !maxLoaded && timeline.length > 24">
						<Observed @observed="loadMoreTimeline" :key="currentTimelinePage">
							<div class="py-3 dF fC aC jC" style="background-color: #eee;">
								<a-icon type="loading" /> Loading
							</div>
						</Observed>
					</template>
				</a-timeline>
			</div>
		</a-card>
	</div>
</template>

<script>
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import BHLoading from "bh-mod/components/common/Loading";
import Observed from '@/components/common/Observed'
import moment from "moment";
import _ from "lodash";

export default {
	components: {
		BHLoading,
		Observed,
		EmptyApp
	},
	props: {
		id: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			loading: false,
			maxLoaded: false,
			currentTimelinePage: 1,
			filterActivities: ['all'],
			sortBy: 'DESC',
			sortOptionList: [
				{ name: 'Date (Newest First)', value: 'DESC' },
				{ name: 'Date (Oldest First)', value: 'ASC' },
			],
			contact: {},
			selectedActivity: null,
			activityDetails: {
				'appointment created': {
					name: 'Appointment Created',
					iconUrl: require("@/assets/timelineIcons/appointment.svg")
				},
				'broadcast email opened': {
					name: 'Broadcast Email Opened',
					iconUrl: require("@/assets/timelineIcons/email-open.svg")
				},
				'broadcast email clicked': {
					name: 'Broadcast Email Clicked',
					iconUrl: require("@/assets/timelineIcons/email-click.svg")
				},
				'broadcast sent': {
					name: 'Broadcast Sent',
					iconUrl: require("@/assets/timelineIcons/email.svg")
				},
				'personal email opened': {
					name: 'Personal Email Opened',
					iconUrl: require("@/assets/timelineIcons/email-open.svg")
				},
				'personal email clicked': {
					name: 'Personal Email Clicked',
					iconUrl: require("@/assets/timelineIcons/email-click.svg")
				},
				'contact created': {
					name: 'Contact Created',
					iconUrl: require("@/assets/timelineIcons/contact.svg")
				},
				'form submitted': {
					name: 'Form Submitted',
					iconUrl: require("@/assets/timelineIcons/form.svg")
				},
				'opportunity created': {
					name: 'Opportunity Created',
					iconUrl: require("@/assets/timelineIcons/opportunity.svg")
				},
				'task created': {
					name: 'Task Created',
					iconUrl: require("@/assets/timelineIcons/task.svg")
				},
				'task completed': {
					name: 'Task Completed',
					iconUrl: require("@/assets/timelineIcons/task-completed.svg")
				},
				'transaction created': {
					name: 'Transaction Created',
					iconUrl: require("@/assets/timelineIcons/transaction.svg")
				},
				'lead score added': {
					name: 'Lead Score Added',
					iconUrl: require("@/assets/timelineIcons/add-lead-score.svg")
				},
				'lead score reduced': {
					name: 'Lead Score Reduced',
					iconUrl: require("@/assets/timelineIcons/reduce-lead-score.svg")
				},
			},
			appointmentTypes: {
				'virtualmeeting': 'Virtual Meeting',
				'phonemeeting': 'Phone Meeting',
				'salescentre': 'Sales Center'
			},
			sourceTypes: {
				'api': 'API',
				'appointment_widget': 'Appointment Widget',
				'event_widget': 'Event Widget',
				'form_submission': 'Form Submission',
				'import': 'Import',
				'reservation': 'Reservation',
				'sync_process': 'SyncProcess',
				'transaction': 'Transaction',
				'webinar_registration': 'Webinar Registration',
				'marketing_workflow': 'Marketing Workflow'
			},
			refreshKey: Math.random(),
		}
	},
	computed: {
		actionList() {
			return Object.keys(this.activityDetails).map(a => ({ name: this.activityDetails[a].name, value: a }))
		},

		contactTimeline() {
			return this.$store.state.contacts.contactTimeline;
		},

		timeline() {
			// Keep the key changing to force a refresh of the timeline
			const key = this.refreshKey;

			const timeline = this.contactTimeline[this.id];
			return timeline?.length ? timeline : [{
				id: 'contact created',
				data: {
					action: 'contact created',
				},
				createdAt: this.contact?.createdAt || Date.now()
			}];
		},

		sources() {
			let list = [
				{ name: 'No Source', id: 'nosource' },
				{ name: 'Email', id: 'email' },
				{ name: 'Cold Call', id: 'coldcall' },
				{ name: 'Advertising', id: 'advertising' }
			]
			if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource) {
				list = this.$store.state.contacts.allSettings.app.options.leadSource
			}

			let listObj = {};
			list.forEach(source => listObj[source.id] = source)

			return listObj;
		},
	},
	watch: {
		sortBy() {
			this.onRefresh();
		},
		filterActivities() {
			this.onRefresh()
		},
	},
	methods: {
		moment,

		onActivitySelect(activity) {
			this.selectedActivity = activity;
		},

		onFilterChange(value) {
			if (value.includes('all') && value.length > 1) {
				if (value[0] === 'all') {
					value = value.slice(1);
				} else if (value[value.length - 1] === 'all') {
					value = ['all']
				}
			} else if (value.length === 0) {
				value = ['all'];
			}
			this.filterActivities = value;
		},

		async fetchTimeline() {
			this.loading = true
			try {
				const start = (this.currentTimelinePage - 1) * 25;
				const id = this.$route.params.id
				let filterQuery = `key=${id}&type=contacts`
				if (this.filterActivities.length && this.filterActivities[0] !== 'all') {
					const query = this.filterActivities.map(fa => `data.action_in[]=${fa}`).join('&');
					filterQuery += `&${query}`
				}
				const { data } = await this.$api.get(`timelines/:instance?${filterQuery}&_start=${start}&_limit=25&_sort=createdAt:${this.sortBy}`)
				const mutation = this.currentTimelinePage === 1 ? 'SET_CONTACT_TIMELINE' : 'ADD_CONTACT_TIMELINE'
				this.$store.commit(mutation, { id: id, timeline: data || [] })
				this.maxLoaded = data.length < 25;
				this.refreshKey = Math.random();
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying to fetch contact timeline details.`))
				}
			} finally {
				this.loading = false
			}
		},

		loadMoreTimeline() {
			this.currentTimelinePage++
			this.fetchTimeline()
		},

		onRefresh: _.debounce(function () {
			this.currentTimelinePage = 1;
			this.$store.commit('SET_CONTACT_TIMELINE', { id: this.id, timeline: [] })
			this.fetchTimeline();
		}, 500),
	},
	mounted() {
		this.fetchTimeline()
	},
	created() {
		if (this.id != '' && this.$store.state.contacts.allContacts[this.id]) {
			this.contact = this.$store.state.contacts.allContacts[this.id]
		} else if (this.id != '' && this.$store.state.contacts.externalContacts[this.id]) {
			this.contact = this.$store.state.contacts.externalContacts[this.id]
		}
	}
}
</script>

<style scoped>
.dateTime {
	color: #9EA0A5;
	min-width: 150px;
}

.activity {
	font-size: large;
}

.activity-details {
	margin: 10px 0 0 25px;
	font-size: medium;
}

.icon {
	max-width: 25px;
	margin-top: 10px;
}

.title {
	color: #a4a4a5;
	margin-right: 5px;
}
</style>
